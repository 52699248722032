/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use 'ui-theme';
@include mat.elevation-classes();
@include mat.app-background();

$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 1em;
}

a {
  color: var(--hyperlink-color);
}

input[type='text'],
textarea {
  font-size: 16px;
}
