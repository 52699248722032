@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-green-apple: #43b72a;
  --color-white: #fff;
  --color-blue-600: #2563eb;
  --color-gray-200: #e2e8f0;
  --color-yellow-400: #f7cb54;

  --hyperlink-color: rgb(55, 43, 209);
}

@layer components {
  h1 {
    @apply text-2xl mb-8;
  }

  h2 {
    @apply text-xl mb-6;
  }
}
